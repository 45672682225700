import React, {useEffect, useRef, useState} from 'react'
import Main from "../../modules/main/main";
import Wizard from "../../modules/wizard/wizard";
import { apiSfa } from "../../services/api/sfa";
import { states } from "../../components/wizard/options/states";
import {useNavigate, useParams} from "react-router-dom";
import {StepContext} from "../../contexts/step-context";
import usePermissions from "../../services/auth/usePermissions";
import useUser from "../../services/auth/useUser";

function SfaForm(props) {
    const isInitialMount = useRef(true);
    const { id, step } = useParams();
    const [userCan, userIs] = usePermissions();
    const user = useUser();
    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [apiError, setApiError] = useState(false);
    const [createMode, setCreateMode] = useState(true);
    const [sfaId, setSfaId] = useState(id);
    const [activeStep, setActiveStep] = useState(0);
    const [formValues, setFormValues] = useState();

    let breadcrumbs = [
        {"name": "Dashboard", "active": false},
        {"name": "Application", "active": false},
        // {"link": "/sfa", "name": "Create", "active": true},
    ];

    const onBack = (step) => {
        setActiveStep(step)
    }

    const fetchData = async(id) => {
        await apiSfa.getSingle(id).then(async res=>{
            if(res.status === 404){
                navigate('/')
            }
            await apiSfa.getSettings().then(res => {
                if (!res.enable_edit_sfa_application) {
                    navigate('/')
                }
            })
            setFormValues(res)
            setLoading(false)
        })
    }

    const getSettings = async() =>  {
        await apiSfa.getSettings().then(res => {
            if (!res.enable_sfa_application) {
                navigate('/')
            }
        })
    }


    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;

            if(id){
                breadcrumbs.push({"name":id, "active": true})
                setCreateMode(false);
                fetchData(id);
            }
            else{
                // fetchOpenApplications();
                getSettings();
                setLoading(false)
            }
        }
        else{
            getSettings();
            fetchData(sfaId);
        }
    }, [sfaId])

    const onSubmit = async (values, step_num) => {
        setLoading(true)
        let returned_value;
        if(createMode){
            // console.log(step_num)
            // console.log(sfaId)
            if(step_num > 0){
                values.append('sfa_id', sfaId)
            }
            await apiSfa.post(values).then((res) => {
                // console.log(res)
                if(res.sfa_id){
                    setSfaId(res.sfa_id);
                }
                returned_value = res;
            });
        }
        else{
            values.append('_method', "PUT")
            await apiSfa.patch(id,values).then((res) => {
                returned_value = res;
            });
        }
        if(!returned_value.status){
            setActiveStep(prevState => prevState + 1)
            setApiError(false);
        } else {
            setApiError(true);
        }
        setLoading(false);
    }

    const steps =  [
        {
            label: 'Agreement',
            description: `
                <p> Please fill out this application <span className="text-danger text-bold">ONLY</span> if you qualify to apply for the ICNA Relief Student Financial Assistance Program.
                    All applications that don't meet the qualifications stated below will be disqualified and not processed.</p>
                <div className="text-danger text-bold"> * ONE STUDENT PER APPLICATION. </div>
                <div className="text-danger text-bold"> * Limited funds are available. </div>
                <p className="text-danger text-bold"> * The application will be evaluated by the Grant Committee.  If additional information is required, you will be requested to submit it. </p>

                <label className="text-bold">Student Financial Assistance Requirements:</label>
                <ol>
                    <li>Most recent Tax Return filed or Refugee paperwork.</li>
                    <li>Proof of any other income from county, state or federal funding.</li>
                    <li>Pay stubs for the past two months.</li>
                    <li>Letter of recommendation (Institute/school).</li>
                    <li>Government-issued ID.</li>
                    <li>Social Security card.</li>
                   
                </ol>               
            `,
            fields:[
                {
                    name:'agree',
                    type:'checkbox',
                    required: true,
                    label: '<strong>I agree to the above Student Financial Assistance Requirements.</strong>',
                    error_text: 'You must agree to the terms.',
                    width: 12
                }
            ],
        },
        {
            label: 'Student Info',
            fields:[
                {
                    section: 'Student Info',
                    name: 'studentNameSection'
                },
                {
                    name:'studentFirstName',
                    type:'text',
                    required: true,
                    label: 'Student First Name',
                    error_text: "Please enter the student's first name.",
                },
                {
                    name:'studentLastName',
                    type:'text',
                    required: true,
                    label: 'Student Last Name',
                    error_text: "Please enter the student's last name.",
                },
                {
                    name:'gradeLevel',
                    type:'text',
                    required: true,
                    label: 'Grade Level K-12th or GPA equivalent',
                    error_text: "Please enter the student's grade level.",
                },
                {
                    name:'studentDob',
                    type:'date',
                    required: true,
                    label: "Student's Date of Birth",
                    error_text: "Please enter the student's date of birth.",
                },
                {
                    section: 'Parent Name',
                    name: 'parentNameSection'
                },
                {
                    name:'parentFirstName',
                    type:'text',
                    required: true,
                    label: 'Parent/Guardian First Name',
                    error_text: "Please enter the parent/guardian's first name.",
                },
                {
                    name:'parentLastName',
                    type:'text',
                    required: true,
                    label: 'Parent/Guardian Last Name',
                    error_text: "Please enter the parent/guardian's last name.",
                },
                {
                    section: 'Address',
                    name: 'addressSection'
                },
                {
                    name:'address',
                    type:'text',
                    required: true,
                    label: 'Address',
                    error_text: "Please enter your address.",
                },
                {
                    name:'city',
                    type:'text',
                    required: true,
                    label: 'City',
                    error_text: "Please enter your city.",
                },
                {
                    name:'state',
                    type:'select',
                    required: true,
                    label: 'State',
                    error_text: "Please select your state.",
                    options: states
                },
                {
                    name:'zipCode',
                    type:'text',
                    required: true,
                    label: 'Zip Code',
                    error_text: "Please enter your zip Code.",
                },
                {
                    section: 'Contact Information',
                    name: 'contactInformationSection'
                },
                {
                    name:'phone',
                    type:'text',
                    required: true,
                    label: 'Phone',
                    error_text: "Please enter your phone number.",
                    format: "tel"
                },
                {
                    name:'email',
                    type:'text',
                    required: true,
                    label: 'Email',
                    error_text: "Please enter your email.",
                },
                {
                    section: 'Household Information',
                    name: 'householdInformationSection'
                },
                {
                    name:'householdSize',
                    type:'text',
                    required: true,
                    label: 'Number of People in the Household',
                    error_text: "Please enter the number of people in the household.",
                },
                {
                    name:'totalMonthlyGrossIncome',
                    type:'number',
                    required: true,
                    label: 'Total Monthly Gross Income',
                    error_text: "Please enter the total monthly gross income for your family.",
                },
                {
                    name:'numberOfChildrenInSchool',
                    type:'number',
                    required: true,
                    label: 'Number of children enrolled in the institution from K-12',
                    error_text: "Please enter the number of children enrolled in the institution from K-12.",
                },
                {
                    section: 'Financial Aid',
                    name: 'financialAidSection'
                },
                {
                    name:'aidRequested',
                    type:'number',
                    required: true,
                    label: 'How much financial aid do you need?',
                    error_text: "Please enter the amount of financial aid you need.",
                },
            ],
        },
        {
            label: 'School Info',
            fields: [
                {
                    section: 'School Name',
                    name: 'schoolNameSection'
                },
                {
                    name:'schoolName',
                    type:'text',
                    required: true,
                    label: 'School Name',
                    error_text: "Please enter the school's name.",
                },
                {
                    section:  'School Address',
                    name: 'schoolAddressSection'
                },
                {
                    name:'schoolAddress',
                    type:'text',
                    required: true,
                    label: 'School Address',
                    error_text: "Please enter the school's address.",
                },
                {
                    name:'schoolCity',
                    type:'text',
                    required: true,
                    label: 'City',
                    error_text: "Please enter your school's city.",
                },
                {
                    name:'schoolState',
                    type:'select',
                    required: true,
                    label: 'School State',
                    options: states
                },
                {
                    name:'schoolZipCode',
                    type:'text',
                    required: true,
                    label: 'School Zip Code',
                    error_text: "Please enter the school's zip Code.",
                },
                {
                    section: "School's Phone Number",
                    name: 'schoolPhoneNumberSection'
                },
                {
                    name:'schoolPhone',
                    type:'text',
                    required: true,
                    label: 'School Phone Number',
                    error_text: "Please enter the school's phone number.",
                    format: "tel"
                },
            ]
        },
        {
            label: 'File Upload Requests',
            fields: [
                {
                    name: "proofOfIncome",
                    label: "Proof of income (PDF only)",
                    type: "file",
                    width: 12,
                    required: true,
                    file_message: `
                <p>Upload any of the following:</p>
                <ul>
                    <li>Most recent Tax Return filed</li>
                    <li>Or pay stubs for the past two months</li>
                    <li>Or Bank statement</li>
                    <li>Or proof of any other income from county, state, or federal funding</li>
                </ul>`
                },
                {
                    name: "letterOfRecommendationFromSchool",
                    label: "Letter of Recommendation from School (PDF only)",
                    type: "file",
                    width: 12,
                    required: false,

                },
                {
                    name: "taxForm1040",
                    label: "Tax Form 1040 (PDF only)",
                    type: "file",
                    width: 12,
                    required: false,

                },
                {
                    name: "governmentId",
                    label: "Government-issued ID (PDF only)",
                    type: "file",
                    width: 12,
                    required: false,
                },
                {
                    name: "socialSecurityCard",
                    label: "Social Security card (PDF only)",
                    type: "file",
                    width: 12,
                    required: false,
                },
                {
                    name: "gpa",
                    label: "Proof of GPA Requirement (PDF only)",
                    type: "file",
                    width: 12,
                    required: false,
                },
                {
                    name: "tuitionInvoice",
                    label: "Tuition Invoice (PDF only)",
                    type: "file",
                    width: 12,
                    required: false,
                },
                {
                    name: "w9Form",
                    label: "W-9 Form (PDF only)",
                    type: "file",
                    width: 12,
                    required: false,
                    file_message: `
                        <p>
                            You can download the W-9 form from here: 
                            <a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" class="d-inline">https://www.irs.gov/pub/irs-pdf/fw9.pdf</a>
                        </p>`
                },
            ]
        },
    ];

    return (
        <Main title="Student Financial Assistance Application" breadcrumbs={breadcrumbs} loading={loading}>
            <div className="small-box mb-0 bg-transparent shadow-none">
                <StepContext.Provider value={{activeStep, setActiveStep}}>
                    <Wizard
                        onSubmit={onSubmit}
                        onBack={onBack}
                        steps={steps}
                        activeStep={activeStep}
                        initialValues={formValues}
                        sfa_id={sfaId}
                        onCompletionMessage={"Your SFA Application has been submitted successfully."}
                        apiError={apiError}
                    />
                </StepContext.Provider>
            </div>

        </Main>
    )
}

export default SfaForm;